import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { auth, googleProvider, microsoftProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box, 
  Alert, 
  CircularProgress 
} from '@mui/material';
import GoogleSignInButton from '../components/GoogleSignInButton';
import MicrosoftSignInButton from '../components/MicrosoftSignInButton';

const LoginPage = () => {
  const { currentUser, jwtToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [googleLoading, setGoogleLoading] = useState(false); 
  const [microsoftLoading, setMicrosoftLoading] = useState(false); 
  const [isSigningIn, setIsSigningIn] = useState(false); // Loading state for overall sign-in process

  // Redirect if already logged in
  useEffect(() => {
    if (currentUser && jwtToken) {
      setIsSigningIn(false); // Stop loading when authentication completes
      navigate('/generate');
    }
  }, [currentUser, jwtToken, navigate]);

  const handleGoogleSignIn = async () => {
    setError('');
    setGoogleLoading(true);
    setIsSigningIn(true); // Start loading
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      setError(err.message);
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleMicrosoftSignIn = async () => {
    setError('');
    setMicrosoftLoading(true);
    setIsSigningIn(true); // Start loading
    try {
      await signInWithPopup(auth, microsoftProvider);
    } catch (err) {
      setError(err.message);
    } finally {
      setMicrosoftLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '85vh',
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            padding: '40px',
            borderRadius: '8px',
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative', // For overlay positioning
          }}
        >
          {isSigningIn && ( // Overlay spinner for entire box
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <Typography variant="h4" gutterBottom align="center" sx={{ marginBottom: '40px' }}>
            Login/Signup
          </Typography>
          <Box sx={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
            <GoogleSignInButton 
              onClick={handleGoogleSignIn} 
              disabled={googleLoading || isSigningIn} 
              sx={{ mb: 2 }}
            />
            {googleLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>

          <Box sx={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
            <MicrosoftSignInButton 
              onClick={handleMicrosoftSignIn} 
              disabled={microsoftLoading || isSigningIn} 
            />
            {microsoftLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>

          {error && 
          <Alert severity="error" sx={{ marginTop: '20px' }}>
            { error === 'Firebase: Error (auth/account-exists-with-different-credential).' &&
              'An account already exists with the same email address but different sign-in credentials. Try signing in with a different method.'
            }
            { error === 'Firebase: Error (auth/popup-closed-by-user).' &&
              'The sign-in popup was closed before completing the sign-in process. Please try again.'
            }
            { error !== 'Firebase: Error (auth/account-exists-with-different-credential).' && error !== 'Firebase: Error (auth/popup-closed-by-user).' &&
              error
            }
          </Alert>
          }
        </Box>
      </Container>
    </Box>
  );
}

export default LoginPage;
