// src/pages/SupportPage.js

import React from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

const SupportPage = () => {
  return (
    <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ mx: 'auto', mt: 4, px: 0 }}>

        {/* Support Contact Card */}
        <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1976D2' }}>
              Contact Support
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              For assistance, please reach out to our support team via email:
            </Typography>
            <Link href="mailto:support@cellsolver.com" variant="body1" color="primary">
              support@cellsolver.com
            </Link>
          </CardContent>
        </Card>

        {/* FAQs Card */}
        <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1976D2' }}>
              Frequently Asked Questions
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="What is CellSolver?"
                  secondary="CellSolver is an AI-driven SaaS platform designed to simplify the creation and understanding of spreadsheet formulas through natural language."
                />
              </ListItem>
              <Divider component="li" sx={{ my: 1 }} />
              
              <ListItem>
                <ListItemText
                  primary="What can the Formula Generator do?"
                  secondary="The Formula Generator helps you create complex formulas by describing them in natural language. It translates your input into accurate spreadsheet formulas."
                />
              </ListItem>
              <Divider component="li" sx={{ my: 1 }} />

              <ListItem>
                <ListItemText
                  primary="How does the Solution Generator work?"
                  secondary="The Solution Generator walks users through building functional spreadsheets from scratch based or solving problems on your needs, saving time and ensuring accuracy."
                />
              </ListItem>
              <Divider component="li" sx={{ my: 1 }} />

              <ListItem>
                <ListItemText
                  primary="Can CellSolver explain formulas?"
                  secondary="Yes! CellSolver not only generates formulas but also provides detailed explanations of how each formula works to enhance your understanding and confidence."
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default SupportPage;
