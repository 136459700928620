// src/pages/CancelPage.js

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const CancelPage = () => {
  return (
    <Container maxWidth="sm" sx={{ paddingY: '80px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Payment Canceled
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your payment was canceled. If this was a mistake, you can try subscribing again.
      </Typography>
      <Box marginTop="20px">
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/subscribe"
          sx={{ borderRadius: '50px', paddingX: '30px', paddingY: '10px', fontWeight: 'bold' }}
        >
          Try Again
        </Button>
      </Box>
    </Container>
  );
};

export default CancelPage;
