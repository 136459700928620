// src/theme.js

import { createTheme } from '@mui/material/styles';

const colors = {
  // Brand
  primary: '#008f5f',      // Main brand color
  primaryLight: '#5df3b7', // Lighter shade for hover or active states
  primaryDark: '#006d4d',  // Darker shade for active states

  secondary: '#4da6ff',    // Secondary brand color
  secondaryLight: '#80c3ff', // Lighter shade for hover or active states
  secondaryDark: '#0069c0',  // Darker shade for active states

  tertiary: '#ff5722',     // Accent color
  tertiaryLight: '#ff7d47', // Lighter shade for hover or active states
  tertiaryDark: '#c41c00',  // Darker shade for active states

  quaternary: '#ff9800',
  quaternaryLight: '#ffc947',
  quaternaryDark: '#c66900',

  // Neutrals
  white: '#ffffff',
  black: '#000000',
  grayLight: '#f4f4f4',
  gray: '#b0b0b0',
  grayDark: '#7d7d7d',

  // Backgrounds
  background: '#ffffff',       // Main background
  backgroundSecondary: '#f4f3f8', // Secondary background
  backgroundGray: '#f4f4f4',   // Light gray background
  surface: '#ffffff',          // Surface color

  // Text
  text: '#333333',             // Main text color
  textSecondary: '#555555',    // Secondary text color
  textDark: '#000000',         // Dark text color
  textLight: '#ffffff',        // Light text color
  textDisabled: '#b0b0b0',     // Disabled text color

  textOnPrimary: '#ffffff',    // Text on primary color
  textOnSecondary: '#ffffff',  // Text on secondary color
  textOnTertiary: '#ffffff',   // Text on tertiary color
  textOnQuaternary: '#ffffff', // Text on quaternary color

  // Alerts
  success: '#4caf50',
  successLight: '#81c784',    // Lighter shade for success messages
  error: '#f44336',
  errorLight: '#e57373',      // Lighter shade for error messages
  warning: '#ff9800',
  warningLight: '#ffb74d',    // Lighter shade for warning messages
  info: '#2196f3',
  infoLight: '#64b5f6',       // Lighter shade for info messages
};

const theme = createTheme({
  colors: colors,
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
      dark: colors.primaryDark,
      contrastText: colors.textOnPrimary,  // Text on primary
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
      contrastText: colors.textOnSecondary,  // Text on secondary
    },
    tertiary: {
      main: colors.tertiary,
      light: colors.tertiaryLight,
      dark: colors.tertiaryDark,
      contrastText: colors.textOnTertiary,  // Text on tertiary
    },
    quaternary: {
      main: colors.quaternary,
      light: colors.quaternaryLight,
      dark: colors.quaternaryDark,
      contrastText: colors.textOnQuaternary,  // Text on quaternary
    },
    success: {
      main: colors.success,
      light: colors.successLight,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
    },
    warning: {
      main: colors.warning,
      light: colors.warningLight,
    },
    info: {
      main: colors.info,
      light: colors.infoLight,
    },
    background: {
      default: colors.background,  // Main background
      paper: colors.surface,       // Surface color for components
    },
    text: {
      primary: colors.text,        // Main text color
      secondary: colors.textSecondary, // Secondary text color
      disabled: colors.textDisabled,  // Disabled text color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme;
