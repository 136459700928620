// src/pages/BillingPage.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Container,
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import ConfirmModal from '../components/ConfirmModal'; // Import the ConfirmModal component
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for navigation

const BillingPage = () => {
  const { jwtToken, subscriptionInfo, currentUser } = useContext(AuthContext);
  const [cancelError, setCancelError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false); // State to handle modal open/close
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);
  const stripe = useStripe();

  const toBeCancelled = currentUser?.subscription_to_be_cancelled

  useEffect(() => {
    setCancelError(null);
    setCancelSuccess(null);
  }, [subscriptionInfo]);

  const handleCancelSubscription = async () => {
    setCancelError(null);
    setCancelSuccess(null);
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cancel-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const { checkout_session_id } = response.data;

      if (checkout_session_id && stripe) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: checkout_session_id,
        });

        if (error) {
          setCancelError(error.message);
        } else {
          setCancelSuccess('Subscription cancellation initiated.');
        }
      } else {
        setCancelSuccess(response.data.message || 'Subscription canceled successfully.');
        // Reload the page to reflect the changes
        window.location.reload();
      }
    } catch (error) {
      setCancelError(error.response?.data?.detail || 'Failed to cancel subscription.');
    } finally {
      setLoading(false);
      setIsCancelModalOpen(false); // Close modal after handling
    }
  };

  const handleExtendSubscription = async () => {
    setLoading(true);
    setCancelError(null);
    setCancelSuccess(null);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/extend-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Check for success message or any specific data in response
      if (response.data.success) {
        setCancelSuccess("Subscription extended successfully.");
        // Reload the page or refresh the subscription data to reflect changes
        window.location.reload();
      } else {
        setCancelError(response.data.message || "Failed to extend subscription.");
      }
    } catch (error) {
      setCancelError(error.response?.data?.detail || "An error occurred while extending subscription.");
    } finally {
      setLoading(false);
      setIsExtendModalOpen(false); // Close modal after handling
      window.location.reload();
    }
  };
  

  const renderSubscriptionDetails = () => {
    if (!subscriptionInfo) return null;

    const planDetails = {
      free: {
        status: 'Free Plan',
        description: 'You are currently using the Free plan with limited features.',
        actions: (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={RouterLink}
            to="/dashboard/settings/plans"
            sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
          >
            Upgrade to Pro
          </Button>
        ),
      },
      pro: {
        status: 'Pro Plan',
        description: 'You are on the Pro plan with full access to all features.',
        actions: (
          <>
            {toBeCancelled ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setIsExtendModalOpen(true)}
                sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
              >
                Extend Plan
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => setIsCancelModalOpen(true)}
                sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Cancel Subscription'}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component={RouterLink}
              to="/dashboard/settings/plans"
              sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
            >
              Change Plan
            </Button>
          </>
        ),
      },
      unlimited: {
        status: 'Unlimited Plan',
        description: 'You are on the Unlimited plan with premium access.',
        actions: (
          <>
            {toBeCancelled ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setIsExtendModalOpen(true)}
                sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
              >
                Extend Plan
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => setIsCancelModalOpen(true)}
                sx={{ mt: 2, borderRadius: '25px', height: '48px' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Cancel Subscription'}
              </Button>
            )}
          </>
        ),
      },
    };

    const { status, description, actions } = planDetails[subscriptionInfo];

    return (
      <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mt: 0 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1976D2' }}>
            {status}
          </Typography>
          <Typography variant="body1" sx={{ color: '#555', mb: 2 }}>
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
          {actions}
        </CardActions>
      </Card>
    );
  };

  return (
    <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ mx: 'auto', mt: 4, px: 0 }}>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderSubscriptionDetails()}
          </Grid>
        </Grid>

        {cancelError && (
          <Alert severity="error" sx={{ mt: 3, borderRadius: '8px' }}>
            {cancelError}
          </Alert>
        )}
        {cancelSuccess && (
          <Alert severity="success" sx={{ mt: 3, borderRadius: '8px' }}>
            {cancelSuccess}
          </Alert>
        )}

        {/* Cancel Modal */}
        <ConfirmModal
          open={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={handleCancelSubscription}
          title="Confirm Downgrade"
          description="Are you sure you want to downgrade to the Free plan? Your current subscription will be canceled at the end of the billing period."
          confirmText="Downgrade"
          cancelText="Cancel"
        />

        {/* Extend Modal */}
        <ConfirmModal
          open={isExtendModalOpen}
          onClose={() => setIsExtendModalOpen(false)}
          onConfirm={handleExtendSubscription}
          title="Confirm Extension"
          description="Are you sure you want to extend your current subscription?"
          confirmText="Extend"
          cancelText="Cancel"
        />
      </Box>
    </Container>
  );
};

export default BillingPage;
