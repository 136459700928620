// src/icons.js

import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faMagic, 
  faLock, 
  faTachometerAlt, 
  faUsers, 
  faCheckCircle 
} from '@fortawesome/free-solid-svg-icons';
import { 
  faFacebook, 
  faTwitter, 
  faLinkedin 
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faMagic, 
  faLock, 
  faTachometerAlt, 
  faUsers, 
  faCheckCircle,
  faFacebook, 
  faTwitter, 
  faLinkedin
);
