// src/pages/SolutionsPage.js

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import {
  Container,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Card,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './SolutionsPage.css';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for navigation
import remarkGfm from 'remark-gfm';

const headingFontFamily = "'Roboto', sans-serif";
const bodyFontFamily = "'Open Sans', sans-serif";
const cardShadow = 3;

const InlineCode = ({ children }) => (
  <Typography
    component="code"
    sx={{
      backgroundColor: '#0015ff22',
      padding: '2px 6px',
      borderRadius: '4px',
      fontFamily: 'monospace',
    }}
  >
    {children}
  </Typography>
);

const BoldInlineCode = ({ children }) => (
  <Typography component="strong" sx={{ fontWeight: 'bold' }}>
    <InlineCode>{children}</InlineCode>
  </Typography>
);

const SolutionsPage = ({ counts }) => {
  const { currentUser, jwtToken, usageHistory, setUsageHistory, refreshJWT } = useContext(AuthContext);
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('excel');
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const MAX_SOLUTIONS = currentUser.limits.solution[0]; // Get the maximum number of solutions from the user's limits

  useEffect(() => {
    const savedDescription = localStorage.getItem('solutionsDescription');
    const savedLanguage = localStorage.getItem('solutionsLanguage');
    const savedResult = localStorage.getItem('solutionsResult');

    if (savedDescription) {
      setDescription(savedDescription);
    }
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
    if (savedResult) {
      setResult(JSON.parse(savedResult));
    }
  }, []);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setError('');
    setResult(null);
    setLoading(true);

    if (!description || !language) {
      setError('Please provide both description and language.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/generate_solution`,
        { description, language },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setResult(response.data.response);
      setUsageHistory([...usageHistory, response.data]);
      localStorage.setItem('solutionsResult', JSON.stringify(response.data.response));
    } catch (err) {
      console.error(err);
      // Refresh the JWT token if it's expired
      if (err.response?.status === 401) {
        await refreshJWT();
        // Retry the request with the new JWT token
        return handleGenerate(e);
      }
      setError(err.response?.data?.detail?.[0]?.msg || 'Error generating solution.');
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    localStorage.setItem('solutionsDescription', newDescription);
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
      localStorage.setItem('solutionsLanguage', newLanguage);
    }
  };

  const handleCopy = (text, type) => {
    setCopySuccess(`${type} copied to clipboard!`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Determine if the generate section should be disabled
  const isGenerateDisabled = counts.solutions >= MAX_SOLUTIONS;

  return (
    <>
      <Container className="solutions-container">
        <Card
          sx={{
            padding: '0px',
            marginBottom: '20px',
            borderRadius: '16px',
            boxShadow: cardShadow,
          }}
        >
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)',
              }}
            >
              <Box sx={{ paddingX: '15px', paddingTop: '10px' }}>
                <Typography
                  variant="h6"
                  align="left"
                  gutterBottom
                  fontWeight="bold"
                  fontFamily={headingFontFamily}
                >
                  Generate Solution
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ position: 'relative' }}> {/* Wrapper for overlay */}
            <form onSubmit={handleGenerate}>
              <Box
                sx={{
                  paddingX: '15px',
                  paddingTop: '25px',
                  paddingBottom: '20px',
                }}
              >
                <TextField
                  label="Description"
                  multiline
                  minRows={4}
                  variant="outlined"
                  fullWidth
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Enter the description of the solution you need..."
                  disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                  value={language}
                  exclusive
                  onChange={handleLanguageChange}
                  aria-label="language selection"
                  sx={{ marginX: '15px', marginBottom: '20px' }}
                  disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                >
                  <ToggleButton value="excel" aria-label="Excel">
                    Excel
                  </ToggleButton>
                  <ToggleButton value="google" aria-label="Google Sheets">
                    Google
                  </ToggleButton>
                  <ToggleButton value="numbers" aria-label="Apple Numbers">
                    Apple
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                sx={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Generate'
                )}
              </Button>
            </form>

            {isGenerateDisabled && ( // Overlay appears only when disabled
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to="/dashboard/settings/plans"
                >
                  Upgrade Plan
                </Button>
              </Box>
            )}
          </Box>

          {error && (
            <Typography color="error" sx={{ marginTop: '20px' }}>
              {error}
            </Typography>
          )}
        </Card>

        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {result && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: '16px',
                  boxShadow: cardShadow,
                }}
              >
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <Box sx={{display: 'flex', boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)' }}>
                    <Box
                      sx={{
                        paddingX: '16px',
                        paddingTop: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        fontFamily={headingFontFamily}
                      >
                        Steps
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingX: '16px',
                    paddingTop: '20px',
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                  }}
                >
                  {result.steps.map((step, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        paddingY: 1,
                        borderBottom: index !== result.steps.length - 1 ? '1px solid #ddd' : 'none',
                        borderRadius: '0px',
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                        Step {index + 1}: {step.title}
                      </Typography>
                      <Typography fontFamily={bodyFontFamily} sx={{ marginTop: '-6px', fontSize: 14}}>
                        {step.action}
                      </Typography>
                      {step.formulas && step.formulas.length > 0 && (
                        step.formulas.map((formula, fIndex) => (
                          <Box sx={{ display: 'flex', alignItems: 'center', marginY: '10px' }} key={fIndex}>
                            <Typography
                              variant="body1"
                              fontFamily={'monospace'}
                              fontWeight={'bold'}
                              fontStyle={'italic'}
                              sx={{
                                marginRight: '10px',
                              }}
                            >
                              {formula.location}:
                            </Typography>
                            <Typography
                              variant="body1"
                              fontFamily={'monospace'}
                              sx={{
                                backgroundColor: '#0015ff22',
                                padding: '2px 6px',
                                borderRadius: '4px',
                                flexGrow: 1,
                              }}
                            >
                              {formula.formula}
                            </Typography>
                            <CopyToClipboard text={formula} onCopy={() => handleCopy(formula, 'Formula')}>
                              <IconButton aria-label="copy formula" size="small">
                                <CopyIcon fontSize="small" />
                              </IconButton>
                            </CopyToClipboard>
                          </Box>
                        ))
                      )}
                      <Typography fontFamily={bodyFontFamily}>
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            strong: ({ children }) => {
                              if (typeof children[0] === 'string' && children[0].includes('`')) {
                                return <BoldInlineCode>{children}</BoldInlineCode>;
                              }
                              return (
                                <Typography component="strong" sx={{ fontWeight: 'bold' }}>
                                  {children}
                                </Typography>
                              );
                            },
                            code: ({ node, inline, children }) => {
                              return <InlineCode>{children}</InlineCode>;
                            },
                          }}
                        >
                          {step.explanation}
                        </ReactMarkdown>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: '16px',
                  boxShadow: cardShadow,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <Box
                    sx={{
                      paddingX: '16px',
                      paddingTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      fontWeight="bold"
                      fontFamily={headingFontFamily}
                    >
                      Explanation
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingX: '16px',
                    paddingY: '10px',
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                  }}
                >
                  <Typography fontFamily={bodyFontFamily}>
                    <ReactMarkdown
                      components={{
                        strong: ({ children }) => {
                          if (typeof children[0] === 'string' && children[0].includes('`')) {
                            return <BoldInlineCode>{children}</BoldInlineCode>;
                          }
                          return (
                            <Typography component="strong" sx={{ fontWeight: 'bold' }}>
                              {children}
                            </Typography>
                          );
                        },
                        code: ({ node, inline, children }) => {
                          return <InlineCode>{children}</InlineCode>;
                        },
                      }}
                    >
                      {result.explanation}
                    </ReactMarkdown>
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}

        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            {copySuccess}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default SolutionsPage;
