// src/pages/UsageHistoryPage.js

import React, {useContext} from 'react';
import { Typography, Box, Container } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import UseCard from '../components/UseCard';

const UsageHistoryPage = () => {
  const { usageHistory } = useContext(AuthContext);
  console.log(usageHistory);
  // Sort the usage history by timestamp
  usageHistory.sort((a, b) => b.timestamp - a.timestamp);
  return (
    <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{maxWidth: '100%'}}>
        <Box sx={{margin: '0 auto'}}>
          {usageHistory ? (
            <Box>
              {usageHistory?.map((data, index) => (
                <UseCard key={index} data={data} />
              ))}
            </Box>
          ) : (
            <Typography variant="body1">Loading usage history...</Typography>
          )}
          {usageHistory.length === 0 && (
            <Typography variant="body1">No usage history found.</Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default UsageHistoryPage;
