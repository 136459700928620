import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, IconButton, Snackbar, Alert, Collapse, Button } from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const UseCard = ({ data }) => {
    const [copied, setCopied] = useState(false);
    const [stepsVisible, setStepsVisible] = useState(false); // State for steps visibility

    // Capitalize first letter of feature
    const feature = data.feature.charAt(0).toUpperCase() + data.feature.slice(1);
    const request = data.request;

    // Convert the language to a more readable format
    const language = data.language === 'excel' ? 'Excel' : data.language === 'google' ? 'Google Sheets' : 'Apple Numbers';

    // Extract formula and total steps based on the feature
    const formula = feature === 'Formula' || feature === 'Debug' ? data.response?.formula : null;
    const totalSteps = feature === 'Solution' ? data.response?.steps?.length : null;

    const handleCopy = (text, label) => {
        setCopied(true);
        console.log(`${label} copied: ${text}`);
        setTimeout(() => setCopied(false), 2000); // Automatically hide the snackbar after 2 seconds
    };

    const toggleStepsVisibility = () => {
        setStepsVisible((prev) => !prev); // Toggle the steps visibility
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    boxShadow: 3,
                    marginBottom: 3,
                    maxWidth: '100%',
                }}
                aria-label={`Card displaying ${feature}`}
            >
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" component="h2" fontWeight="bold" fontSize='1.2rem'>
                            {feature}
                        </Typography>
                        <Typography variant="h6" component="h2" fontSize='1.05rem' sx={{ marginLeft: 1 }}>
                            ({language})
                        </Typography>
                        {formula && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 2 }}>
                                <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleCopy(formula, 'Formula')}
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    padding: '2px 10px',
                                }}
                                >
                                    <Typography
                                        variant="outlined"
                                        fontSize="0.95rem"
                                        sx={{
                                            wordBreak: 'break-word',
                                            flexGrow: 1,
                                        }}
                                    >
                                        {formula}
                                    </Typography>
                                    <CopyToClipboard text={formula} onCopy={() => handleCopy(formula, 'Formula')}>
                                        <IconButton
                                            aria-label="Copy formula to clipboard"
                                            size="small"
                                            sx={{ ml: 1, transition: 'color 0.2s', padding: 0 }}
                                        >
                                            <CopyIcon fontSize="small" />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Button>
                            </Box>
                        )}

                        {totalSteps !== null && (
                            <Button
                                variant="outlined"
                                size="small"
                                endIcon={stepsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                onClick={toggleStepsVisibility}
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    padding: '2px 10px',
                                    marginLeft: 2,
                                    fontSize:"0.95rem"
                                }}
                            >
                                {totalSteps} Steps
                            </Button>
                        )}
                    </Box>

                    <Typography variant="body1" color="textSecondary">
                        {request}
                    </Typography>

                    {/* Collapsible Steps Section */}
                    {totalSteps !== null && (
                        <Collapse in={stepsVisible} timeout="auto" unmountOnExit>
                            <Box sx={{ mt: 2 }}>
                                {data.response?.steps?.map((step, index) => (
                                    <Box key={index} sx={{ marginBottom: 1, paddingY: 1, borderBottom: '1px solid #ddd' }}>
                                        <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                            Step {index + 1}: {step.title}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }}>{step.action}</Typography>
                                        {step.formulas && step.formulas.length > 0 && (
                                            step.formulas.map((formula, fIndex) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }} key={fIndex}>
                                                    <Typography
                                                        variant="body1"
                                                        fontFamily="monospace"
                                                        fontWeight="bold"
                                                        fontStyle="italic"
                                                        sx={{ marginRight: 1 }}
                                                    >
                                                        {formula.location}:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        fontFamily="monospace"
                                                        sx={{ backgroundColor: '#0015ff22', padding: '2px 6px', borderRadius: '4px', flexGrow: 1 }}
                                                    >
                                                        {formula.formula}
                                                    </Typography>
                                                    <CopyToClipboard text={formula.formula} onCopy={() => handleCopy(formula.formula, 'Formula')}>
                                                        <IconButton aria-label="copy formula" size="small">
                                                            <CopyIcon fontSize="small" />
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                </Box>
                                            ))
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Collapse>
                    )}
                </CardContent>
            </Card>

            {/* Snackbar for copy confirmation */}
            <Snackbar
                open={copied}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setCopied(false)}
            >
                <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard!
                </Alert>
            </Snackbar>
        </>
    );
};

export default UseCard;
