import React from 'react';
import { Box, Typography, Button, Container, Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import hero from '../../assets/hero.png';

const HeroSection = () => {
  const theme = useTheme();

  return (
    <Box className="hero-section" sx={{ zIndex: 0, width: '100%', margin: '0 auto' }}>
      {/* Main Content */}
      <Container
        sx={{
          paddingY: 5, 
          marginBottom: 5,
          marginTop: 5,
          zIndex: 0,
          position: 'relative',
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Text Section - Takes 1/3 of the space */}
          <Grid item xs={12} md={5} sx={{ textAlign: 'left', alignContent: 'center', marginY: 0 }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                color: '#333',
                marginBottom: 1,
                fontSize: '2.8rem', // Responsive font size
              }}
            >
              <b>AI Powered</b><br />Spreadsheet Formulas
            </Typography>
            <Typography
              variant="h5"
              component="p"
              sx={{
                color: '#555',
                marginBottom: 3,
                fontSize: '1.5rem', // Responsive font size
              }}
            >
              Generate Complex Formulas in Seconds
            </Typography>
            {/* Link the Button to /login */}
            <Button
              component={Link}
              to="/login" // Navigate to the login page
              variant="contained"
              color="primary"
              size="large"
              sx={{
                borderRadius: '40px',
                paddingY: 0.75, // Responsive padding
                paddingX: 3, // Responsive padding
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              Get Started
            </Button>
          </Grid>

          {/* Graphic Section - Takes 2/3 of the space */}
          <Grid item xs={12} md={7}>
            {/* Replace with your desired graphic */}
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={hero}// Replace with the graphic you want
                alt="Graphic illustration"
                style={{ width: '100%', height: 'auto', paddingTop: 20 }} // Make image responsive
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;
