// src/components/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

// ProtectedRoute Component
const ProtectedRoute = () => {
  const { currentUser, jwtToken } = useContext(AuthContext);

  if (currentUser && jwtToken) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
