// src/components/PricingSection.js

import React from 'react';
import { Container, Typography, Card, CardContent, Button, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../../theme';

const pricingPlans = [
  {
    title: 'Free',
    price: 'Trial',
    description: 'Test the waters',
    features: [
      { icon: '❕', text: '5 Formula Trial' },
      { icon: '❕', text: '1 Solution Trial' },
      { icon: '❕', text: '1 Debug Trial' },
    ],
    buttonText: 'Test Out CellSolver',
    buttonVariant: 'outlined',
    buttonColor: 'secondary',
    link: '/login', // Assuming Free plan requires login/signup
    highlightColor: theme.palette.secondary.main,
  },
  {
    title: 'Pro',
    price: '$3.29 / Month',
    description: 'Boost your productivity',
    features: [
      { icon: '✅', text: 'Unlimited Formulas' },
      { icon: '❕', text: '4 Solutions per day' },
      { icon: '❕', text: '10 Debugs per day' },
    ],
    buttonText: 'Unlock Pro Features',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    link: '/login', // Redirects to Stripe purchase
    highlightColor: theme.palette.primary.main,
  },
  {
    title: 'Unlimited',
    price: '$9.99 / Month',
    description: 'Unleash your potential',
    features: [
      { icon: '✅', text: 'Unlimited Formulas' },
      { icon: '✅', text: 'Unlimited Solutions' },
      { icon: '✅', text: 'Unlimited Debugs' },
    ],
    buttonText: 'Go Unlimited',
    buttonVariant: 'contained',
    buttonColor: 'quaternary',
    link: '/login', // Redirects to Stripe purchase
    highlightColor: theme.palette.quaternary.main,
  }
];

const PricingSection = () => {
  return (
    <Box 
      sx={{ 
        paddingY: 5,
        marginBottom: 10,
      }}
    >
      <Container
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" gutterBottom align="center" sx={{ marginBottom: '40px', fontWeight: 'bold' }}>
          Choose Your Plan
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  padding: '20px',
                  borderRadius: '16px', // More modern rounded corners
                  border: `2px solid ${plan.highlightColor}`, // Highlighted border
                  boxShadow: 6, // Stronger shadow for modern look
                  '&:hover': {
                    transform: 'scale(1.05)', // Modern hover effect
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {plan.title}
                  </Typography>
                  <Typography variant="h4" gutterBottom sx={{ marginBottom: '10px', textAlign: 'left' }}>
                    {plan.price}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom sx={{ marginBottom: '20px', textAlign: 'left' }}>
                    {plan.description}
                  </Typography>

                  <Box component="ul" sx={{ 
                    listStyleType: 'none', 
                    padding: 0, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',  // Centers the entire list
                    justifyContent: 'center', 
                    width: '100%', 
                  }}>
                    {plan.features.map((feature, idx) => (
                      <Box key={idx} sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        width: '100%',  // Full width of the parent container
                        maxWidth: '300px',  // Adjust max width for readability
                        padding: '10px 0',  // Consistent padding
                        borderBottom: idx !== plan.features.length - 1 ? '1px solid #ddd' : 'none',  // Dividers between rows
                      }}>
                        <Typography variant="body1" sx={{ 
                          display: 'inline-flex', 
                          alignItems: 'center', 
                          marginRight: '10px',  // Space between icon and text
                        }}>
                          {feature.icon} {/* Display icon inline */}
                        </Typography>
                        <Typography variant="body1" sx={{ 
                          textAlign: 'left', 
                          flex: 1,  // Allow text to take the remaining space
                        }}>
                          {feature.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box> {/* Spacing between features and button */}
                      <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {plan.title !== 'Free' && "* Cancel anytime"}
                        {plan.title === 'Free' && "* Limited trial"}
                      </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color={plan.buttonColor}
                    component={Link}
                    to={plan.link}
                    fullWidth
                    sx={{
                      marginTop: '20px',
                      borderRadius: '50px', // Rounded button
                      padding: '10px 0',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    {plan.buttonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingSection;
