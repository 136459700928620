// src/components/MicrosoftSignInButton.js

import React from 'react';
import { Button } from '@mui/material';
import { Microsoft as MicrosoftIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '10px 20px',
}));

const MicrosoftSignInButton = ({ onClick, disabled }) => {
  return (
    <StyledButton
        color='secondary'
        variant="contained"
        startIcon={<MicrosoftIcon />}
        onClick={onClick}
        disabled={disabled}
    >
        Sign in with Microsoft
    </StyledButton>
  );
};

export default MicrosoftSignInButton;
