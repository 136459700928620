import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import {
  Container,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Card,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for navigation

import theme from '../theme';

const headingFontFamily = "'Roboto', sans-serif";

const bodyFontFamily = "'Open Sans', sans-serif";

const cardShadow = 3;

const InlineCode = ({ children }) => (
  <Typography component="code" sx={{ color: '#000', backgroundColor: '#0015ff22', padding: '2px 6px', borderRadius: '4px', fontFamily: 'monospace' }}>
    {children}
  </Typography>
);

const BoldInlineCode = ({ children }) => (
  <Typography component="strong" sx={{ fontWeight: 'bold' }}>
    <InlineCode>{children}</InlineCode>
  </Typography>
);

const DebugPage = ({ counts }) => {
  const { currentUser, jwtToken, usageHistory, setUsageHistory, refreshJWT } = useContext(AuthContext);
  const [brokenFormula, setBrokenFormula] = useState('');
  const [language, setLanguage] = useState('excel');
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const MAX_DEBUGS = currentUser.limits.debug[0]; // Get the maximum number of debugs from the user's limits

  useEffect(() => {
    const savedFormula = localStorage.getItem('brokenFormula');
    const savedLanguage = localStorage.getItem('language');
    const savedResult = localStorage.getItem('debuggedResult');
    const savedErrorDescription = localStorage.getItem('errorDescription');

    if (savedFormula) {
      setBrokenFormula(savedFormula);
    }
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
    if (savedResult) {
      setResult(JSON.parse(savedResult));
    }
    if (savedErrorDescription) {
      setErrorDescription(savedErrorDescription);
    }
  }, []);

  const handleDebug = async (e) => {
    e.preventDefault();
    setError('');
    setResult(null);
    setLoading(true);

    if (!brokenFormula || !language) {
      setError('Please provide both a broken formula and language.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/generate_debug`,
        { 'broken_formula': brokenFormula, language, 'error_description': errorDescription },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setResult(response.data.response);
      setUsageHistory([...usageHistory, response.data]);
      localStorage.setItem('debuggedResult', JSON.stringify(response.data.response));
    } catch (err) {
      console.error(err);
      // Refresh the JWT token if it's expired
      if (err.response?.status === 401) {
        await refreshJWT();
        // Retry the request with the new JWT token
        return handleDebug(e);
      }
      setError(err.response?.data?.detail?.[0]?.msg || 'Error debugging formula.');
    } finally {
      setLoading(false);
    }
  };

  const handleFormulaChange = (e) => {
    const newFormula = e.target.value;
    setBrokenFormula(newFormula);
    localStorage.setItem('brokenFormula', newFormula);
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
      localStorage.setItem('language', newLanguage);
    }
  };

  const handleErrorDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setErrorDescription(newDescription);
    localStorage.setItem('errorDescription', newDescription);
  };

  const handleCopy = (text, type) => {
    setCopySuccess(`${type} copied to clipboard!`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Determine if the generate section should be disabled
  console.log(counts.debug, MAX_DEBUGS);
  const isGenerateDisabled = counts.debug >= MAX_DEBUGS;

  return (
    <>
      <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <Card sx={{ padding: '0px', marginBottom: '20px', borderRadius: '16px', boxShadow: cardShadow }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)' }}>
              <Box sx={{ paddingX: '15px', paddingTop: '10px' }}>
                <Typography variant="h6" align="left" gutterBottom fontWeight="bold" fontFamily={headingFontFamily}>
                  Debug Formula
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ position: 'relative' }}> {/* Wrapper for overlay */}
            <form onSubmit={handleDebug}>
              <Box sx={{ paddingX: '15px', paddingTop: '25px', paddingBottom: '20px' }}>
                <TextField
                  label="Broken Formula"
                  variant="outlined"
                  fullWidth
                  value={brokenFormula}
                  onChange={handleFormulaChange}
                  placeholder="Enter the broken formula..."
                  disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                />
              </Box>
              <Box sx={{ paddingX: '15px', paddingTop: '10px', paddingBottom: '20px' }}>
                <TextField
                  label="Additional Description (Optional)"
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  value={errorDescription}
                  onChange={handleErrorDescriptionChange}
                  placeholder="Describe what's going wrong, if needed..."
                  disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                  value={language}
                  exclusive
                  onChange={handleLanguageChange}
                  aria-label="language selection"
                  sx={{ marginX: '15px', marginBottom: '20px' }}
                  disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                >
                  <ToggleButton value="excel" aria-label="Excel">
                    Excel
                  </ToggleButton>
                  <ToggleButton value="google" aria-label="Google Sheets">
                    Google
                  </ToggleButton>
                  <ToggleButton value="numbers" aria-label="Apple Numbers">
                    Apple
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading || isGenerateDisabled} // Disable during loading or if limits reached
                sx={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Debug'
                )}
              </Button>
            </form>

            {isGenerateDisabled && ( // Overlay appears only when disabled
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to="/dashboard/settings/plans"
                >
                  Upgrade Plan
                </Button>
              </Box>
            )}
          </Box>

          {error && (
            <Typography color="error" sx={{ marginTop: '20px' }}>
              {error}
            </Typography>
          )}
        </Card>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        )}

        {result && (
          <Grid container spacing={3}>
            {/* Fixed Formula Section */}
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '16px', boxShadow: cardShadow, height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
                  <Box sx={{ display: 'flex', boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)' }}>
                    <Box sx={{ display: 'flex', paddingLeft: '16px', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom fontWeight="bold" fontFamily={headingFontFamily} sx={{ marginBottom: '0px' }}>
                        Fixed Formula
                      </Typography>
                    </Box>
                    <CopyToClipboard text={result.formula} onCopy={() => handleCopy(result.fixedFormula, 'Fixed Formula')} sx={{ marginY: '10px', marginX: '6px' }}>
                      <IconButton aria-label="copy fixed formula" size="small">
                        <CopyIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                  <Box
                    sx={{
                      borderBottomRightRadius: '16px',
                      borderTopRightRadius: '16px',
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-word',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      height: 'inherit',
                      minHeight: 'inherit',
                    }}
                  >
                    <Typography
                      variant="span"
                      fontFamily={'monospace'}
                      fontSize={16}
                      sx={{
                        marginX: '16px',
                        marginY: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-word',
                      }}
                    >
                      {result.formula}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>

            {/* Explanation Section */}
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '16px', boxShadow: cardShadow }}>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.12)' }}>
                    <Box sx={{ paddingX: '15px', paddingTop: '10px' }}>
                      <Typography variant="h6" gutterBottom fontWeight="bold" fontFamily={headingFontFamily}>
                        Explanation
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ paddingX: '15px', paddingY: '1px', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}>
                  <Typography fontFamily={bodyFontFamily}>
                    <ReactMarkdown
                      components={{
                        strong: ({ children }) => {
                          if (typeof children[0] === 'string' && children[0].includes('`')) {
                            return <BoldInlineCode>{children}</BoldInlineCode>;
                          }
                          return <Typography component="strong" sx={{ fontWeight: 'bold' }}>{children}</Typography>;
                        },
                        code: ({ node, inline, children }) => {
                          return <InlineCode>{children}</InlineCode>;
                        },
                      }}
                    >
                      {result.explanation}
                    </ReactMarkdown>
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}

        {/* Snackbar for Copy Success */}
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            {copySuccess}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default DebugPage;
