// src/pages/ConfigsPage.js

import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Switch,
  Slider,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Container,
} from '@mui/material';

const ConfigsPage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [notificationLevel, setNotificationLevel] = useState('medium');
  const [syncFrequency, setSyncFrequency] = useState(30); // in minutes

  return (
    <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ mx: 'auto', mt: 4, px: 0}}>
        {/* Settings Card: Notifications */}
        <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1976D2' }}>
              Notification Settings
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel id="notification-level-label">Notification Level</InputLabel>
              <Select
                labelId="notification-level-label"
                id="notification-level"
                value={notificationLevel}
                label="Notification Level"
                onChange={(e) => setNotificationLevel(e.target.value)}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" sx={{ color: '#555' }}>
              Adjust the sensitivity of notifications you receive.
            </Typography>
          </CardContent>
        </Card>

        {/* Settings Card: Sync Frequency */}
        <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1976D2' }}>
              Sync Settings
            </Typography>
            <Typography gutterBottom>
              Sync Frequency (minutes)
            </Typography>
            <Slider
              value={syncFrequency}
              onChange={(e, value) => setSyncFrequency(value)}
              step={5}
              min={5}
              max={120}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <Typography variant="body2" sx={{ color: '#555' }}>
              Set how frequently the app syncs data in the background.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ConfigsPage;
