// src/pages/SuccessPage.js

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
  return (
    <Container maxWidth="sm" sx={{ paddingY: '80px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for subscribing to CellSolver Pro. You now have access to all premium features.
      </Typography>
      <Box marginTop="20px">
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard"
          sx={{ borderRadius: '50px', paddingX: '30px', paddingY: '10px', fontWeight: 'bold' }}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default SuccessPage;
