// src/pages/SubscribePage.js

import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, CircularProgress, Box, Alert } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';

const SubscribePage = () => {
  const { jwtToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscribe = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const { checkout_session_id } = response.data;

      if (!stripe) {
        throw new Error('Stripe has not loaded yet.');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: checkout_session_id,
      });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError(err.response?.data?.detail || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: '80px' }}>
      <Typography variant="h4" gutterBottom align="center">
        Subscribe to CellSolver
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Upgrade to our Pro plan to unlock unlimited formula generation and priority support.
      </Typography>
      {error && (
        <Alert severity="error" sx={{ marginBottom: '20px' }}>
          {error}
        </Alert>
      )}
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubscribe}
          disabled={loading}
          sx={{ paddingX: '30px', paddingY: '10px', borderRadius: '50px', fontWeight: 'bold' }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Upgrade to Pro'}
        </Button>
      </Box>
    </Container>
  );
};

export default SubscribePage;
