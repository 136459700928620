// src/components/Footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  if (isDashboardRoute) {
    // Do not render Footer on Dashboard routes
    return null;
  }

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 0,
      }}
    >
      <Typography variant="body2">
        &copy; {new Date().getFullYear()} CellSolver. All rights reserved. |{' '}
        <Link href="https://docs.google.com/document/d/1Vb6ADL6Q5_o01q-y35vft_g1lC1iooqsijCIat59NMM/edit?usp=sharing" color="inherit" underline="hover">
          Terms of Service
        </Link>{' '}
        |{' '}
        <Link href="https://docs.google.com/document/d/1CJvIBotlR0oN_LWnXDyb7eBTclgNxon8njsD3wpGuvs/edit?usp=sharing" color="inherit" underline="hover">
          Privacy Policy
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
