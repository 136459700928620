// src/pages/PlansPage.jsx

import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import theme from '../theme';
import ConfirmModal from '../components/ConfirmModal';

// Define your plans
const plans = [
  {
    id: 'free',
    title: 'Free',
    price: '$0',
    description: 'Test the waters',
    features: [
      { icon: '❕', text: '5 Formula Trial' },
      { icon: '❕', text: '1 Solution Trial' },
      { icon: '❕', text: '1 Debug Trial' },
    ],
    buttonText: 'Test Out CellSolver',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    link: '/login',
    highlightColor: theme.palette.secondary.main,
  },
  {
    id: 'pro',
    title: 'Pro',
    price: '$3.29 / Month',
    description: 'Boost your productivity',
    features: [
      { icon: '✅', text: 'Unlimited Formulas' },
      { icon: '❕', text: '4 Solutions per day' },
      { icon: '❕', text: '10 Debugs per day' },
    ],
    buttonText: 'Unlock Pro Features',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    link: null,
    highlightColor: theme.palette.primary.main,
  },
  {
    id: 'unlimited',
    title: 'Unlimited',
    price: '$9.99 / Month',
    description: 'Unleash your potential',
    features: [
      { icon: '✅', text: 'Unlimited Formulas' },
      { icon: '✅', text: 'Unlimited Solutions' },
      { icon: '✅', text: 'Unlimited Debugs' },
    ],
    buttonText: 'Go Unlimited',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    link: null,
    highlightColor: theme.palette.quaternary.main,
  },
];

// Mapping of Stripe Price IDs to Internal Plan IDs
const PRICE_ID_TO_PLAN = {
  [process.env.REACT_APP_STRIPE_PRO_PRICE_ID]: 'pro',
  [process.env.REACT_APP_STRIPE_UNLIMITED_PRICE_ID]: 'unlimited',
};

// Mapping of Internal Plan IDs to Stripe Price IDs
const PLAN_TO_PRICE_ID = {
  pro: process.env.REACT_APP_STRIPE_PRO_PRICE_ID,
  unlimited: process.env.REACT_APP_STRIPE_UNLIMITED_PRICE_ID,
};

const PlansPage = () => {
  const { subscriptionInfo, jwtToken, currentUser } = useContext(AuthContext);
  const stripe = useStripe();
  const [loadingPlanId, setLoadingPlanId] = useState(null);
  const [error, setError] = useState(null);
  const [currentPlan, setCurrentPlan] = useState('free');

  // Modal State Management
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const toBeCancelled = currentUser?.subscription_to_be_cancelled;

  useEffect(() => {
    // Determine current plan based on subscriptionInfo (Stripe Price ID)
    if (subscriptionInfo) {
      const plan = subscriptionInfo;
      setCurrentPlan(plan || 'free');
    } else {
      setCurrentPlan('free');
    }
  }, [subscriptionInfo]);

  const handleSubscribe = async (planId) => {
    setLoadingPlanId(planId);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
        { plan_id: planId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const { checkout_session_id } = response.data;

      if (!stripe) {
        throw new Error('Stripe has not loaded yet.');
      }

      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId: checkout_session_id,
      });

      if (stripeError) {
        setError(stripeError.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data.detail === 'string'
      ) {
        setError(err.response.data.detail);
      } else if (
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data.detail)
      ) {
        const errorMessage = err.response.data.detail
          .map((detail) => detail.msg)
          .join(', ');
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoadingPlanId(null);
    }
  };

  const handleUpgrade = async () => {
    if (!selectedPlanId) return;

    setLoadingPlanId(selectedPlanId);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/update-subscription`,
        { new_plan_id: selectedPlanId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // After successful upgrade, you might want to refresh the subscription info
      // Here, we simply reload the page
      window.location.reload();
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data.detail === 'string'
      ) {
        setError(err.response.data.detail);
      } else if (
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data.detail)
      ) {
        const errorMessage = err.response.data.detail
          .map((detail) => detail.msg)
          .join(', ');
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoadingPlanId(null);
      setIsUpgradeModalOpen(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!selectedPlanId) return;

    setLoadingPlanId(selectedPlanId);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cancel-subscription`,
        { cancel_at_period_end: false }, // Immediate cancellation
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // After successful cancellation, you might want to refresh the subscription info
      // Here, we simply reload the page
      window.location.reload();
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data.detail === 'string'
      ) {
        setError(err.response.data.detail);
      } else if (
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data.detail)
      ) {
        const errorMessage = err.response.data.detail
          .map((detail) => detail.msg)
          .join(', ');
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoadingPlanId(null);
      setIsCancelModalOpen(false);
    }
  };

  const handleExtend = async () => {
    if (!currentPlan || currentPlan === 'free') return;
  
    setLoadingPlanId(currentPlan);
    setError(null);
  
    try {
      // Send request to backend to extend the subscription
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/extend-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // After successful extension, reload the page or update subscription info
      window.location.reload();
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data.detail === 'string'
      ) {
        setError(err.response.data.detail);
      } else if (
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data.detail)
      ) {
        const errorMessage = err.response.data.detail
          .map((detail) => detail.msg)
          .join(', ');
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoadingPlanId(null);
    }
  };
  

  const getButtonProps = (plan) => {
    // If cancellation is pending
    if (toBeCancelled) {
      if (plan.id === currentPlan) {
        return {
          disabled: false,
          onClick: () => {setIsExtendModalOpen(true)},
          link: null,
          variant: 'contained',
          color: 'primary',
          text: 'Extend Plan',
        };
      } else if (plan.id === 'free') {
        return {
          disabled: true,
          onClick: () => {},
          link: null,
          variant: 'outlined',
          color: 'secondary',
          text: 'Cancellation Pending',
        };
      } else {
        // Allow upgrades even if cancellation is pending
        return {
          disabled: loadingPlanId === plan.id,
          onClick: () => {
            setSelectedPlanId(plan.id);
            setIsUpgradeModalOpen(true);
          },
          link: null,
          variant: plan.buttonVariant,
          color: plan.buttonColor,
          text:
            loadingPlanId === plan.id ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              plan.buttonText
            ),
        };
      }
    }

    // Default button behavior if no cancellation is pending
    if (currentPlan === 'free') {
      if (plan.id === 'free') {
        return {
          disabled: false,
          onClick: () => {},
          link: plan.link,
          variant: plan.buttonVariant,
          color: plan.buttonColor,
          text: plan.buttonText,
        };
      } else {
        return {
          disabled: loadingPlanId === plan.id,
          onClick: () => handleSubscribe(plan.id),
          link: null,
          variant: plan.buttonVariant,
          color: plan.buttonColor,
          text:
            loadingPlanId === plan.id ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              plan.buttonText
            ),
        };
      }
    } else if (currentPlan === 'pro') {
      if (plan.id === 'pro') {
        return {
          disabled: true,
          onClick: () => {},
          link: null,
          variant: 'contained',
          color: 'success',
          text: 'Current Plan',
        };
      } else if (plan.id === 'unlimited') {
        return {
          disabled: loadingPlanId === plan.id,
          onClick: () => {
            setSelectedPlanId(plan.id);
            setIsUpgradeModalOpen(true);
          },
          link: null,
          variant: plan.buttonVariant,
          color: plan.buttonColor,
          text:
            loadingPlanId === plan.id ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              plan.buttonText
            ),
        };
      } else {
        return {
          disabled: false,
          onClick: () => {
            setSelectedPlanId(plan.id);
            setIsCancelModalOpen(true);
          },
          link: null,
          variant: 'outlined',
          color: 'secondary',
          text: 'Cancel Subscription',
        };
      }
    } else if (currentPlan === 'unlimited') {
      if (plan.id === 'unlimited') {
        return {
          disabled: true,
          onClick: () => {},
          link: null,
          variant: 'contained',
          color: 'success',
          text: 'Current Plan',
        };
      } else if (plan.id === 'pro') {
        return {
          disabled: true,
          onClick: () => {},
          link: null,
          variant: 'contained',
          color: 'primary',
          text: 'Pro Plan',
        };
      } else {
        return {
          disabled: false,
          onClick: () => {
            setSelectedPlanId(plan.id);
            setIsCancelModalOpen(true);
          },
          link: null,
          variant: 'outlined',
          color: 'secondary',
          text: 'Cancel Subscription',
        };
      }
    }

    return {
      disabled: false,
      onClick: () => {},
      link: null,
      variant: 'contained',
      color: 'primary',
      text: 'Subscribe',
    };
  };
  

  return (
    <Container sx={{ paddingY: 4 }}>
      {error && (
        <Alert severity="error" sx={{ marginY: 5 }}>
          {error}
        </Alert>
      )}
      <Grid
        container
        spacing={4}
        justifyContent="center"
      >
        {plans.map((plan) => {
          const buttonProps = getButtonProps(plan);
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={plan.id}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: '16px',
                  border: `2px solid ${plan.highlightColor}`,
                  boxShadow: 6,
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: 'bold', textAlign: 'left' }}
                  >
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ marginBottom: '10px', textAlign: 'left' }}
                  >
                    {plan.price}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ marginBottom: '20px', textAlign: 'left' }}
                  >
                    {plan.description}
                  </Typography>

                  <Box
                    component="ul"
                    sx={{
                      listStyleType: 'none',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    {plan.features.map((feature, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          padding: '10px 0',
                          borderBottom:
                            idx !== plan.features.length - 1
                              ? '1px solid #ddd'
                              : 'none',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            display: 'inline-flex',
                            marginRight: '10px',
                          }}
                        >
                          {feature.icon}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: 'left',
                            flex: 1,
                          }}
                        >
                          {feature.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box> {/* Spacing between features and button */}
                      <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {plan.id !== 'free' && "* Cancel anytime"}
                        {plan.id === 'free' && "* Limited trial"}
                      </Typography>
                  </Box>

                  {buttonProps.link ? (
                    <Button
                      variant={buttonProps.variant}
                      color={buttonProps.color}
                      component="a"
                      href={buttonProps.link}
                      fullWidth
                      sx={{
                        marginTop: '20px',
                        borderRadius: '50px',
                        padding: '10px 0',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      {buttonProps.text}
                    </Button>
                  ) : (
                    <Button
                      variant={buttonProps.variant}
                      color={buttonProps.color}
                      onClick={buttonProps.onClick}
                      fullWidth
                      disabled={buttonProps.disabled}
                      sx={{
                        marginTop: '20px',
                        borderRadius: '50px',
                        padding: '10px 0',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      {buttonProps.text}
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Extend Modal */}
      <ConfirmModal
        open={isExtendModalOpen}
        onClose={() => setIsExtendModalOpen(false)}
        onConfirm={handleExtend}
        title="Confirm Extension"
        description="Are you sure you want to extend your current subscription?"
        confirmText="Extend"
        cancelText="Cancel"
      />

      {/* Upgrade Modal */}
      <ConfirmModal
        open={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        onConfirm={handleUpgrade}
        title="Confirm Upgrade"
        description="Are you sure you want to upgrade to the Unlimited plan? You will be billed immediately for the new plan."
        confirmText="Upgrade"
        cancelText="Cancel"
      />

      {/* Cancel Modal */}
      <ConfirmModal
        open={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={handleCancelSubscription}
        title="Confirm Downgrade"
        description="Are you sure you want to downgrade to the Free plan? Your current subscription will be canceled at the end of the billing period."
        confirmText="Downgrade"
        cancelText="Cancel"
      />
    </Container>
  );
};

export default PlansPage;
