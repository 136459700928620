// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

// Create Context
export const AuthContext = createContext();

// Auth Provider Component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [usageHistory, setUsageHistory] = useState(null);

  // Listen for authentication state changes

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
        setCurrentUser(fbUser);
        if (fbUser) {
            try {
            const idToken = await fbUser.getIdToken(/* forceRefresh */ true);
            // Send this token to the backend
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/token/firebase`, { token: idToken });
            const { jwt, subscription, user  } = response.data;
            setJwtToken(jwt);
            setSubscriptionInfo(subscription);
            setCurrentUser({
                ...user,
                subscription,
            });
            // Store JWT in localStorage
            localStorage.setItem('authToken', jwt);
            // Fetch usage history
            const usageResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/usage_history`, {
                headers: {
                Authorization: `Bearer ${jwt}`,
                },
            });
            setUsageHistory(usageResponse.data);
            } catch (error) {
            console.error('Error fetching JWT:', error);
            setJwtToken(null);
            localStorage.removeItem('authToken');
            }
        } else {
            setJwtToken(null);
            localStorage.removeItem('authToken');
        }
        setLoading(false);
        });
    
        return () => unsubscribe();
    }, []);
  

  // Function to log out
  const logout = () => {
    auth.signOut();
    setJwtToken(null);
    localStorage.removeItem('authToken');
  };

  // Function to refresh the JWT
  const refreshJwt = async () => {
    if (currentUser) {
      const idToken = await currentUser.getIdToken(/* forceRefresh */ true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/token/firebase`, { token: idToken });
      const { jwt, subscription, user } = response.data;
      setJwtToken(jwt);
      setSubscriptionInfo(subscription);
      setCurrentUser({
        ...user,
        subscription,
      });
      // Store JWT in localStorage
      localStorage.setItem('authToken', jwt);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, jwtToken, subscriptionInfo, usageHistory, logout, setUsageHistory, refreshJwt }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
