// src/pages/Dashboard.js

import React, { useState, useContext, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  useMediaQuery,
  Divider,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FunctionsIcon from '@mui/icons-material/Functions';
import DebugIcon from '@mui/icons-material/Adb'; // New Icon for Debug
import BillingIcon from '@mui/icons-material/Payment';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment'; // New Icon for Solutions
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import GeneratePage from './GeneratePage';
import ProfilePage from './ProfilePage';
import SettingsPage from './SettingsPage';
import BillingPage from './BillingPage';
import PlansPage from './PlansPage';
import ConfigsPage from './ConfigsPage';
import UsageHistoryPage from './UsageHistoryPage';
import DashboardHome from './DashboardHome';
import SolutionsPage from './SolutionsPage'; // New Import
import DebugPage from './DebugPage';
import SupportPage from './SupportPage';
import { AuthContext } from '../context/AuthContext';

import logo from '../assets/logos/logo_v002_(b)_(gb).png';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, jwtToken, subscriptionInfo, usageHistory } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [generatedResult, setGeneratedResult] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const drawerWidth = isMobile ? '80%' : '240px'; // Adjust drawer width based on screen size
  const selectedColor = theme.palette.action.selected;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem) => {
    handleProfileMenuClose();
    if (menuItem === 'Logout') {
      logout();
      navigate('/login');
    } else if (menuItem === 'Profile') {
      navigate('/dashboard/settings/profile');
    } else if (menuItem === 'Settings') {
      navigate('/dashboard/settings/configs');
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const isSelected = (path) => location.pathname === path;

  const handleDrawerItemClick = () => {
    if (isMobile) {
      setDrawerOpen(false); // Close the drawer if in mobile view
    }
  };

  // State to hold usage counts
  const [counts, setCounts] = useState({
    formulas: 0,
    debug: 0,
    solutions: 0,
  });

  // Effect to update counts whenever usageHistory changes
  useEffect(() => {
    const newCounts = {
      formulas: 0,
      debug: 0,
      solutions: 0,
    };

    usageHistory.forEach((usage) => {
      const feature = usage.feature.toLowerCase();
      // If the subscriptionInfo is 'pro' or 'unlimited', then filter out any with a timestamp older than 24 hours ago
      if (subscriptionInfo === 'pro' || subscriptionInfo === 'unlimited') {
        console.log(usage.timestamp);
        const twentyFourHoursAgo = (Date.now() - 24 * 60 * 60 * 1000) / 1000;
        console.log(twentyFourHoursAgo);
        if (new Date(usage.timestamp) < twentyFourHoursAgo) return; 
      }
      if (feature === 'formula') newCounts.formulas += 1;
      if (feature === 'debug') newCounts.debug += 1;
      if (feature === 'solution') newCounts.solutions += 1;
    });

    setCounts(newCounts);
  }, [usageHistory]);

  const drawerContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <List>
        <ListItem
          button
          component={Link}
          to="/dashboard"
          sx={isSelected('/dashboard') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/formulas"
          sx={isSelected('/dashboard/formulas') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <FunctionsIcon />
          </ListItemIcon>
          <ListItemText primary="Formulas" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/solutions"
          sx={isSelected('/dashboard/solutions') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Solutions" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/debug"
          sx={isSelected('/dashboard/debug') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <DebugIcon />
          </ListItemIcon>
          <ListItemText primary="Debug" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/settings/billing"
          sx={isSelected('/dashboard/settings/billing') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <BillingIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
        {subscriptionInfo?.status !== 'active' && (
          <ListItem
            button
            component={Link}
            to="/dashboard/settings/plans"
            sx={isSelected('/dashboard/settings/plans') ? { backgroundColor: selectedColor } : {}}
            onClick={handleDrawerItemClick}
          >
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Plans" />
          </ListItem>
        )}
        <ListItem
          button
          component={Link}
          to="/dashboard/settings/configs"
          sx={isSelected('/dashboard/settings/configs') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/usage-history"
          sx={isSelected('/dashboard/usage-history') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/dashboard/settings/support"
          sx={isSelected('/dashboard/settings/support') ? { backgroundColor: selectedColor } : {}}
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItem>
      </List>
      <Divider />
      {/* Usage Box */}
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Usage
        </Typography>
        {/* Formulas Usage */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Formulas</Typography>
            <Typography variant="body2">
              {counts.formulas} out of {currentUser.limits.formula[0]} / {currentUser.limits.formula[1]}
            </Typography>
          </Box>
          <Tooltip
            title={`Used ${counts.formulas} out of ${currentUser.limits.formula[0]} / ${currentUser.limits.formula[1]} Formulas`}
          >
            <LinearProgress
              variant="determinate"
              value={(counts.formulas / currentUser.limits.formula[0]) * 100}
              color="primary"
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Tooltip>
        </Box>
        {/* Debug Usage */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Debug</Typography>
            <Typography variant="body2">
              {counts.debug} out of {currentUser.limits.debug[0]} / {currentUser.limits.debug[1]}
            </Typography>
          </Box>
          <Tooltip
            title={`Used ${counts.debug} out of ${currentUser.limits.debug[0]} / ${currentUser.limits.debug[1]} Debug`}
          >
            <LinearProgress
              variant="determinate"
              value={(counts.debug / currentUser.limits.debug[0]) * 100}
              color="secondary"
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Tooltip>
        </Box>
        {/* Solutions Usage */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Solutions</Typography>
            <Typography variant="body2">
              {counts.solutions} out of {currentUser.limits.solution[0]} / {currentUser.limits.solution[1]}
            </Typography>
          </Box>
          <Tooltip
            title={`Used ${counts.solutions} out of ${currentUser.limits.solution[0]} / ${currentUser.limits.solution[1]} Solutions`}
          >
            <LinearProgress
              variant="determinate"
              value={(counts.solutions / currentUser.limits.solution[0]) * 100}
              color="success"
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Tooltip>
        </Box>
        {/* See Plans Button */}
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => navigate('/dashboard/settings/plans')}
          sx={{ mt: 1 }}
        >
          See Plans
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar */}
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginTop: '5px' }}>
            <img src={logo} alt="CellSolver Logo" style={{ height: '40px' }} />
          </Typography>
          <IconButton size="large" edge="end" color="inherit" onClick={handleProfileMenuOpen}>
            <AccountCircle />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose}>
            <MenuItem onClick={() => handleMenuItemClick('Profile')}>Profile</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Settings')}>Settings</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Logout')}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>{drawerContent}</Box>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route
            path="formulas"
            element={<GeneratePage counts={counts} generatedResult={generatedResult} setGeneratedResult={setGeneratedResult} />}
          />
          <Route path="solutions" element={<SolutionsPage counts={counts} />} />
          <Route path="debug" element={<DebugPage counts={counts} />} />
          <Route path="settings/*" element={<SettingsPage />}>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="configs" element={<ConfigsPage />} />
            <Route path="plans" element={<PlansPage />} />
            <Route path="" element={<ProfilePage />} />
            <Route path="support" element={<SupportPage />} />
          </Route>
          <Route path="usage-history" element={<UsageHistoryPage />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
