// src/pages/LandingPage.js

import React from 'react';
import HeroSection from '../components/landing/HeroSection';
import BenefitsSection from '../components/landing/BenefitsSection';
import FeaturesSection from '../components/landing/FeaturesSection';
import TestimonialsSection from '../components/landing/TestimonialsSection';
import PricingSection from '../components/landing/PricingSection'; // Import PricingSection

import { Box } from '@mui/material';

import theme from '../theme';

import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
  return (
    <div>
      <Helmet>
        {/* Basic SEO */}
        <title>CellSolver - AI Spreadsheet Formulas</title>
        <meta name="description" content="AI Powered Spreadsheet Formulas, Generate Complex Spreadsheet Formulas and Solutions in Seconds" />
        <link rel="canonical" href="https://cellsolver.com" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="CellSolver - AI Powered Spreadsheet Formulas" />
        <meta property="og:description" content="Generate complex spreadsheet formulas and solutions in seconds with AI-driven technology." />
        <meta property="og:url" content="https://cellsolver.com" />

        {/* Additional SEO Tags */}
        <meta name="author" content="CellSolver Team" />
        <meta name="keywords" content="AI spreadsheet formulas, generate formulas, Excel solutions, AI-powered spreadsheets" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <HeroSection />
      <BenefitsSection />
      <PricingSection />
    </div>
  );
};


export default LandingPage;
