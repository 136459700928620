// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import SubscribePage from './pages/SubscribePage'; // New Import
import SuccessPage from './pages/SuccessPage';     // New Import
import CancelPage from './pages/CancelPage';       // New Import
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Load Stripe outside of a component’s render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <Elements stripe={stripePromise}>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            
            {/* Stripe Checkout Success and Cancel Routes */}
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/cancel" element={<CancelPage />} />
            
            {/* Subscribe Route Wrapped with Elements Provider */}
            <Route
              path="/subscribe"
              element={
                  <SubscribePage />
              }
            />
            
            {/* Protected Dashboard Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard/*" element={<Dashboard />} />
            </Route>
            
            {/* Redirect /generate to /dashboard/formulas */}
            <Route path="/generate" element={<Navigate to="/dashboard/formulas" replace />} />

            {/* Optional: Add a 404 Not Found Route */}
            {/* <Route path="*" element={<NotFoundPage />} /> */}
          </Routes>
        </Elements>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
