// src/pages/SettingsPage.js

import React from 'react';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';

const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Determine the current tab based on the URL
  const currentTab = () => {
    if (location.pathname.endsWith('/profile')) return 0;
    if (location.pathname.endsWith('/billing')) return 1;
    if (location.pathname.endsWith('/configs')) return 2;
    if (location.pathname.endsWith('/plans')) return 3;
    if (location.pathname.endsWith('/support')) return 4;
    return 0; // default to Profile
  };

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate('profile');
        break;
      case 1:
        navigate('billing');
        break;
      case 2:
        navigate('configs');
        break;
      case 3:
        navigate('plans');
        break;
      case 4:
        navigate('support');
        break;
      default:
        navigate('profile');
    }
  };

  return (
    <Box>
      <Tabs value={currentTab()} onChange={handleTabChange} aria-label="settings tabs" sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tab label="Profile" component={Link} to="profile" />
        <Tab label="Billing" component={Link} to="billing" />
        <Tab label="Configurations" component={Link} to="configs" />
        <Tab label="Plans" component={Link} to="plans" />
        <Tab label="Support" component={Link} to="support" />
      </Tabs>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SettingsPage;
