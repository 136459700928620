// src/pages/ProfilePage.js

import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Avatar,
  Grid,
  Container,
} from '@mui/material';

const ProfilePage = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ mx: 'auto', mt: 4, px: 0 }}>
        <Card sx={{ boxShadow: 3, borderRadius: '16px', overflow: 'hidden', mt: 4 }}>
          <CardContent>
            <Grid container spacing={4} alignItems="center">
              {/* Avatar and Basic Info */}
              <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar
                  alt={currentUser.name || 'User'}
                  src={currentUser.avatar || '/default-avatar.png'} // Placeholder avatar
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                {/* User Name */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {currentUser.name || 'User Name'}
                </Typography>
                {/* Email */}
                <Typography variant="body1" sx={{ color: '#555' }}>
                  <strong>Email: </strong>{currentUser.email}
                </Typography>
              </Grid>
            </Grid>

            {/* Divider */}
            <Divider sx={{ my: 3 }} />

            {/* Additional Profile Details */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  <strong>Account Created: </strong>{new Date(currentUser.createdAt).toLocaleDateString()}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  <strong>Subscription: </strong>{currentUser.subscription || 'Free Plan'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ProfilePage;
