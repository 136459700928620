// src/components/GoogleSignInButton.js

import React from 'react';
import { Button } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  textTransform: 'none',
  fontWeight: 'bold',
  padding: '10px 20px',
}));

const GoogleSignInButton = ({ onClick, disabled }) => {
  return (
    <StyledButton
      color='secondary'
      variant="contained"
      startIcon={<GoogleIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Sign in with Google
    </StyledButton>
  );
};

export default GoogleSignInButton;
