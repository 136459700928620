// src/pages/DashboardHome.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import FunctionsIcon from '@mui/icons-material/Functions';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DebugIcon from '@mui/icons-material/Adb';
import BillingIcon from '@mui/icons-material/Payment';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { AuthContext } from '../context/AuthContext';
import LinearProgress from '@mui/material/LinearProgress';

const DashboardHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser, subscriptionInfo, usageHistory } = useContext(AuthContext);

  // State to hold usage counts
  const [counts, setCounts] = useState({
    formulas: 0,
    debug: 0,
    solutions: 0,
  });

  // Effect to update counts whenever usageHistory changes
  useEffect(() => {
    const newCounts = {
      formulas: 0,
      debug: 0,
      solutions: 0,
    };

    usageHistory.forEach((usage) => {
      const feature = usage.feature.toLowerCase();
      // If the subscriptionInfo is 'pro' or 'unlimited', then filter out any with a timestamp older than 24 hours ago
      if (subscriptionInfo === 'pro' || subscriptionInfo === 'unlimited') {
        console.log(usage.timestamp);
        const twentyFourHoursAgo = (Date.now() - 24 * 60 * 60 * 1000) / 1000;
        console.log(twentyFourHoursAgo);
        if (new Date(usage.timestamp) < twentyFourHoursAgo) return; 
      }
      if (feature === 'formula') newCounts.formulas += 1;
      if (feature === 'debug') newCounts.debug += 1;
      if (feature === 'solution') newCounts.solutions += 1;
    });

    setCounts(newCounts);
  }, [usageHistory]);

  // Define dashboard options
  const options = [
    {
      title: 'Formulas',
      icon: <FunctionsIcon fontSize="large" color="primary" />,
      link: '/dashboard/formulas',
      description: 'Create and manage your formulas.',
    },
    {
      title: 'Solutions',
      icon: <AssignmentIcon fontSize="large" color="success" />,
      link: '/dashboard/solutions',
      description: 'Explore and utilize solutions.',
    },
    {
      title: 'Debug',
      icon: <DebugIcon fontSize="large" color="secondary" />,
      link: '/dashboard/debug',
      description: 'Debug your formulas.',
    },
    {
      title: 'Billing',
      icon: <BillingIcon fontSize="large" color="warning" />,
      link: '/dashboard/settings/billing',
      description: 'Manage your billing information.',
    },
    {
      title: 'Usage History',
      icon: <HistoryIcon fontSize="large" color="info" />,
      link: '/dashboard/usage-history',
      description: 'View your usage history.',
    },
    {
      title: 'Plans',
      icon: <MonetizationOnIcon fontSize="large" color="success" />,
      link: '/dashboard/settings/plans',
      description: 'Upgrade or change your plan.',
      condition: subscriptionInfo?.status !== 'active', // Show only if subscription is not active
    },
  ];

  return (
    <Box>
      {/* Welcome Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
          Welcome to your Dashboard, {currentUser.name}!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Here you can access all your tools and manage your account efficiently.
        </Typography>
      </Box>

      {/* Options Grid */}
      <Grid container spacing={4}>
        {options
          .filter((option) => option.condition !== false)
          .map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.title}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  padding: 0,
                }}
                elevation={3}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        mr: 2,
                      }}
                    >
                      {option.icon}
                    </Avatar>
                    <Typography variant="h6">{option.title}</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {option.description}
                  </Typography>
                </CardContent>
                <Button
                  size="small"
                  color="primary"
                  component={Link}
                  to={option.link}
                  variant="contained"
                  fullWidth
                  sx={{
                    margin: 0,
                    textTransform: 'none',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                  }}
                >
                  Go to {option.title}
                </Button>
              </Card>
            </Grid>
          ))}
      </Grid>

      {/* Usage Statistics */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Your Usage Statistics
        </Typography>
        <Grid container spacing={4}>
          {/* Formulas Usage */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              elevation={3}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <FunctionsIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Formulas</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {counts.formulas} used out of {currentUser.limits.formula[0]} allowed.
              </Typography>
              <Tooltip
                title={`Used ${counts.formulas} out of ${currentUser.limits.formula[0]} allowed Formulas`}
              >
                <LinearProgress
                  variant="determinate"
                  value={(counts.formulas / currentUser.limits.formula[0]) * 100}
                  color="primary"
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Tooltip>
            </Card>
          </Grid>

          {/* Debug Usage */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              elevation={3}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <DebugIcon color="secondary" sx={{ mr: 1 }} />
                <Typography variant="h6">Debug</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {counts.debug} used out of {currentUser.limits.debug[0]} allowed.
              </Typography>
              <Tooltip
                title={`Used ${counts.debug} out of ${currentUser.limits.debug[0]} allowed Debugs`}
              >
                <LinearProgress
                  variant="determinate"
                  value={(counts.debug / currentUser.limits.debug[0]) * 100}
                  color="secondary"
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Tooltip>
            </Card>
          </Grid>

          {/* Solutions Usage */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              elevation={3}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AssignmentIcon color="success" sx={{ mr: 1 }} />
                <Typography variant="h6">Solutions</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {counts.solutions} used out of {currentUser.limits.solution[0]} allowed.
              </Typography>
              <Tooltip
                title={`Used ${counts.solutions} out of ${currentUser.limits.solution[0]} allowed Solutions`}
              >
                <LinearProgress
                  variant="determinate"
                  value={(counts.solutions / currentUser.limits.solution[0]) * 100}
                  color="success"
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Tooltip>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardHome;
