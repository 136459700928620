// src/components/BenefitsSection.js

import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import theme from '../../theme';

// Import the local video
import demoVideo from '../../assets/demo_v005.mp4';

const BenefitsSection = () => {
  return (
    <Box className="benefits-section" sx={{ paddingY: 5, marginBottom: 5 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} alignItems="center">
            <Box className="video-container" sx={{ borderRadius: '20px', overflow: 'hidden' }}>
              {/* Local video element that behaves like a GIF */}
              <video
                src={demoVideo}
                alt="Demo Video"
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px' }}
                autoPlay
                loop
                muted
                playsInline
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} alignContent="center">
            <Box sx={{ alignSelf: 'center' }}>
              <Typography variant="h3" sx={{ marginBottom: 7, fontWeight: 'bold' }}>
                Why Choose CellSolver?
              </Typography>

              <Grid container sx={{ marginBottom: 7 }} alignItems="center">
                <Grid item xs={1}>
                  <Typography variant="h5">🚀</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5">Generate Excel, Google Sheets, and Apple Numbers formulas instantly.</Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 7 }} alignItems="center">
                <Grid item xs={1}>
                  <Typography variant="h5">💡</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5">Create step by step solutions to difficult spreadsheet problems.</Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 7 }} alignItems="center">
                <Grid item xs={1}>
                  <Typography variant="h5">🤖</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5">Leverage AI to understand and optimize your spreadsheets.</Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Typography variant="h5">📈</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5">Increase productivity and reduce errors.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BenefitsSection;
